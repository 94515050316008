import React from 'react';
import { createRoot } from 'react-dom';
import App from './App';
import './index.css';

const rootElement = document.getElementById('root');

const RootComponent = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const root = createRoot(rootElement);
root.render(<RootComponent />);