import React, { useState, useEffect } from 'react';
import { updateProfile, uploadProfilePic } from '../utils/api';

const Profile = () => {
  const [profile, setProfile] = useState({ name: '', email: '', walletAddress: '' });
  const [profilePic, setProfilePic] = useState(null);

  useEffect(() => {
    // Fetch user profile data (example static data)
    // In practice, you might fetch this from an API endpoint
    setProfile({ name: 'John Doe', email: 'john@example.com', walletAddress: '0x1234567890abcdef' });
  }, []);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateProfile(profile);
      alert('Profile updated successfully');
    } catch (err) {
      console.error('Update failed', err);
    }
  };

  const handleFileChange = (e) => {
    setProfilePic(e.target.files[0]);
  };

  const handleUploadProfilePic = async () => {
    if (profilePic) {
      try {
        await uploadProfilePic(profilePic);
        alert('Profile picture uploaded successfully');
      } catch (err) {
        console.error('Upload failed', err);
      }
    }
  };

  return (
    <div>
      <h2>Profile Settings</h2>
      <form onSubmit={handleProfileUpdate}>
        <label>
          Name:
          <input type="text" value={profile.name} onChange={(e) => setProfile({ ...profile, name: e.target.value })} />
        </label>
        <label>
          Email:
          <input type="email" value={profile.email} onChange={(e) => setProfile({ ...profile, email: e.target.value })} />
        </label>
        <label>
          Wallet Address:
          <input type="text" value={profile.walletAddress} onChange={(e) => setProfile({ ...profile, walletAddress: e.target.value })} />
        </label>
        <button type="submit">Update Profile</button>
      </form>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUploadProfilePic}>Upload Profile Picture</button>
    </div>
  );
};

export default Profile;
