import React, { useState, useEffect } from 'react';
import { getMetrics } from '../utils/api';

const Metrics = () => {
  const [metrics, setMetrics] = useState({ totalAssets: 0, totalRevenue: 0 });

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const data = await getMetrics();
        setMetrics(data);
      } catch (err) {
        console.error('Failed to fetch metrics', err);
      }
    };
    fetchMetrics();
  }, []);

  return (
    <div>
      <h2>Dashboard Metrics</h2>
      <p>Total Assets: ${metrics.totalAssets}</p>
      <p>Total Revenue: ${metrics.totalRevenue}</p>
    </div>
  );
};

export default Metrics;
