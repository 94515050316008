import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Profile from './components/Profile';
import Metrics from './components/Metrics';
import ReferralSystem from './components/ReferralSystem';
import TransactionsTable from './components/TransactionsTable';
import AdminDashboard from './components/AdminDashboard';
import Notifications from './components/Notifications';
import PrivateRoute from './PrivateRoute';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/metrics" component={Metrics} />
        <PrivateRoute path="/referral" component={ReferralSystem} />
        <PrivateRoute path="/transactions" component={TransactionsTable} />
        <PrivateRoute path="/admin" component={AdminDashboard} />
        <PrivateRoute path="/notifications" component={Notifications} />
        <Route path="/" exact component={Login} />
      </Routes>
    </Router>
  );
};

export default App;
