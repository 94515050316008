import React, { useState, useEffect } from 'react';
import { getReferralCode, generateReferralCode } from '../utils/api';

const ReferralSystem = () => {
  const [referralCode, setReferralCode] = useState('');

  useEffect(() => {
    const fetchReferralCode = async () => {
      try {
        const data = await getReferralCode();
        setReferralCode(data.code);
      } catch (err) {
        console.error('Failed to fetch referral code', err);
      }
    };
    fetchReferralCode();
  }, []);

  const handleGenerateCode = async () => {
    try {
      const data = await generateReferralCode();
      setReferralCode(data.code);
    } catch (err) {
      console.error('Failed to generate referral code', err);
    }
  };

  return (
    <div>
      <h2>Referral System</h2>
      <p>Your Referral Code: {referralCode}</p>
      <button onClick={handleGenerateCode}>Generate New Referral Code</button>
    </div>
  );
};

export default ReferralSystem;
