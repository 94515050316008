import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://elite.mydapp3sync.online/api/';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const login = async (username, password) => {
  const response = await api.post('login.php', { username, password });
  return response.data;
};

export const register = async (username, password, email) => {
  const response = await api.post('register.php', { username, password, email });
  return response.data;
};

export const updateProfile = async (profileData) => {
  const response = await api.post('updateProfile.php', profileData, {
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  });
  return response.data;
};

export const forgotPassword = async (email) => {
  const response = await api.post('forgotPassword.php', { email });
  return response.data;
};

export const uploadProfilePic = async (file) => {
  const formData = new FormData();
  formData.append('profilePic', file);

  const response = await api.post('uploadProfilePic.php', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return response.data;
};

export const getMetrics = async () => {
  const response = await api.get('metrics.php', {
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  });
  return response.data;
};

export const getReferralCode = async () => {
  const response = await api.get('referralCode.php', {
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  });
  return response.data;
};

export const generateReferralCode = async () => {
  const response = await api.post('generateReferralCode.php', {}, {
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  });
  return response.data;
};

export const getTransactions = async () => {
  const response = await api.get('transactions.php', {
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  });
  return response.data;
};

export const getNotifications = async () => {
  const response = await api.get('notifications.php', {
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  });
  return response.data;
};
